import { fetchWithTimeout } from 'global-utils/api';
import { GEO_API_URL } from 'config/api';
import { sendErrorLog } from 'global-utils/audit';

async function fetchGeoLocation() {
  let result;
  try {
    result = await fetchWithTimeout(GEO_API_URL);

    if (result.status === 404) {
      sendErrorLog({
        errorCode: '404',
        origin: 'whereami'
      });
    }
  } catch (err) {
    if (err.name === 'AbortError') {
      sendErrorLog({
        errorCode: 'cancelled',
        origin: 'whereami'
      });
    } else {
      sendErrorLog({
        errorCode: err.message,
        origin: 'whereami'
      });
    }
    /*  const loadingScreen = document.getElementById('cbg-loading-screen');
    loadingScreen?.remove(); */

    console.error(err);
  }
  return result;
}

export default fetchGeoLocation;

import { getProjectInfo, getScriptInfo } from 'global-utils/get-attributes';
import { getPreferredLang } from 'global-utils/get-language';
import { getPoliciesUrl } from 'global-utils/get-policies-link';
import {
  ageGateParam,
  isCookiesEnabled,
  noCookiesParam,
  userGaveConsent,
  getCookieValue,
  deleteCookie
} from 'global-utils/cookie-utils';
import { initAGateway, initCConsent } from './init-packages';
import { getMinimumAccessAge, getAgeFromCookie } from './age-utils';
import { isTst, isDev } from 'config/env';

const { GTMId, showAgeGateway, checkCookies } = getScriptInfo();

const { prjMarket, projectId } = getProjectInfo();
const lang = getPreferredLang();
const { cookiesUrl, policiesUrl } = getPoliciesUrl(lang, prjMarket, projectId);

const currentPath = window.location.href.replace(window.location.origin, '');
const currentPage = window.location.href;

const hideCookies = noCookiesParam === 'true' || !checkCookies;

const stripTrailingSlash = (str: string) =>
  str?.endsWith('/') ? str.slice(0, -1) : str;

const isPolicyPage = () => {
  if (!cookiesUrl || !policiesUrl) {
    return false;
  }

  const sanitizeURL = stripTrailingSlash(currentPage);
  const sanitizePath = stripTrailingSlash(currentPath);
  const cookiesPath = stripTrailingSlash(cookiesUrl);
  const privacyPath = stripTrailingSlash(policiesUrl);

  const isPolicy =
    sanitizeURL.startsWith(cookiesPath) ||
    sanitizeURL.startsWith(privacyPath) ||
    sanitizePath.startsWith(cookiesPath) ||
    sanitizePath.startsWith(privacyPath);

  return isPolicy;
};

const shouldLoadCC =
  !hideCookies && isCookiesEnabled() && !userGaveConsent() && !isPolicyPage();

const shouldLoadAG = showAgeGateway && !ageGateParam && !isPolicyPage();

export const loadCCIfShould = (userLocation: string, continent: string) => {
  if (!projectId || !prjMarket) {
    console.error(
      'cbgCConsent:: projectId or prjMarket attribute is missing, could not load cookies banner'
    );
    return;
  }
  if (!GTMId) {
    console.error(
      'cbgCConsent:: GTMId attribute is missing, could not load cookies banner'
    );
    return;
  }
  if (shouldLoadCC) {
    initCConsent(userLocation, continent);
  }
};

const agCookieVal: string[] | undefined = getCookieValue('cbgAge');
const ageCookieSet = agCookieVal && typeof agCookieVal !== 'undefined';
const cookieValueIsLegacy =
  agCookieVal && Array.isArray(agCookieVal) && agCookieVal[0].length > 2;

export const loadAGIfShould = (userLocation: string) => {
  if (!shouldLoadAG) {
    return;
  }
  // temporray patch to enforce users that validated age on legacy AG
  // to be presented with the new one and renew the cookie value
  if (ageCookieSet && cookieValueIsLegacy) {
    deleteCookie('cbgAge');
    // Delete code split in Dev and Test envs when changes are approved
    initAGateway(userLocation);
    return;
  }

  if (ageCookieSet) {
    // if the plugin fails to define the user location, we should use the project market
    // as a legal drinking age reference instead
    // Delete code split in Dev and Test envs when changes are approved
    const ageReference =
      isTst || isDev
        ? prjMarket?.toUpperCase()
        : userLocation || prjMarket?.toUpperCase();
    const minimumAccessAge = getMinimumAccessAge(ageReference);
    /* if we know that the user 
    is old enough to enter the website, 
    age gate should be bypassed */
    if (minimumAccessAge) {
      // TO DO, old cookie value is always higher than user age
      // verify that the user age value is no higher than 30
      const userAge = getAgeFromCookie();

      if (Number(userAge) >= minimumAccessAge) {
        /*  const loadingScreen = document.getElementById('cbg-loading-screen');
        loadingScreen?.remove();
        document.body.style.overflow = 'initial'; */
        return;
      }
    }
  }

  // Delete code split in Dev and Test envs when changes are approved
  initAGateway(userLocation);

  /* const loadingScreen = document.getElementById('cbg-loading-screen');
  loadingScreen?.remove(); */
};

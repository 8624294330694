export const fetchWithTimeout = async (
  url: string,
  timeout = 2000,
  customHeaders?: { [key: string]: string }
) => {
  const ctrl = new AbortController();
  const { signal } = ctrl;

  let abortRequest: any;
  const settings = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...customHeaders
    },
    signal
  };

  // set timeout
  const timeOut = new Promise((resolve) => {
    abortRequest = setTimeout(async () => {
      if (ctrl) {
        ctrl.abort();
      } // abort fetch request

      resolve('timeout');
    }, timeout);
  });

  let fetchData;
  try {
    fetchData = await fetch(url, settings);
    fetchData = await fetchData
      .text()
      // do not throw error if response is empty
      .then((text) => (text ? JSON.parse(text) : {}));
  } finally {
    clearTimeout(abortRequest);
  }

  const result = await Promise.race([timeOut, fetchData]);
  return result;
};

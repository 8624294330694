import { getCookieValue } from 'global-utils/cookie-utils';
import countriesInfo from '@age-gateway/config/legal-ages.json';
import { fallbackMinimumAge } from '@age-gateway/config/fallback-config';

const restrictions: Array<{
  countryCode: string;
  countryName: string;
  minimumAccessAge: number;
}> = countriesInfo && countriesInfo.countriesList;

const getMinimumAccessAge = (country: string) => {
  const marketAgeInfo = restrictions?.filter(
    (item) => item.countryCode?.toLowerCase() === country?.toLowerCase()
  );

  const age = marketAgeInfo[0]?.minimumAccessAge;

  if (typeof age !== 'number') {
    console.error(
      'user access age for the selected country is not available, will use fallback value'
    );
    return fallbackMinimumAge;
  }
  return age;
};

const getAgeFromCookie = () => {
  const agCookieValue = getCookieValue('cbgAge');
  const userAge = agCookieValue && agCookieValue[0];

  return userAge;
};

export { getAgeFromCookie, getMinimumAccessAge };

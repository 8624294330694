import { AUDIT_URL } from 'config/api';
import { fetchWithTimeout } from 'global-utils/api';
import { getProjectInfo, getCConsentInfo } from 'global-utils/get-attributes';
import { isCookiesAllowed } from 'global-utils/cookie-utils';

async function sendAuditInfo({
  type,
  market,
  country
}: {
  type?: string;
  market?: string;
  country?: string;
}) {
  let result;
  const queryParams = `?${type ? `userConsent=${type}&` : ''}${
    market ? `market=${market}&` : ''
  }${country ? `country=${country}` : ''}`;
  const URL = `${AUDIT_URL}/cookie-consent${queryParams}`;
  try {
    result = await fetchWithTimeout(URL, 2000, {
      'X-Forwarded-PathParams': window.location.pathname
    });
  } catch (err) {
    console.error(err);
  }

  return result;
}

async function sendAgeGateAudit({
  rememberMe,
  olderThan
}: {
  rememberMe?: boolean;
  olderThan?: boolean;
}) {
  let result;
  const queryParams = `?${
    typeof rememberMe !== 'undefined' ? `rememberMe=${rememberMe}&` : ''
  }${typeof olderThan !== 'undefined' ? `olderThan=${olderThan}` : ''}`;
  const URL = `${AUDIT_URL}/age-gateway${queryParams}`;

  try {
    result = await fetchWithTimeout(URL, 2000, {
      'X-Forwarded-PathParams': window.location.pathname
    });
  } catch (err) {
    console.error(err);
  }

  return result;
}

async function sendErrorLog({
  origin,
  errorCode
}: {
  origin: string;
  errorCode?: string;
}) {
  const { userLocation: country } = getCConsentInfo();
  const { prjMarket: market } = getProjectInfo();
  const cookiesAllowed = isCookiesAllowed();
  let result;
  const loggerEid = sessionStorage.getItem('cbgccpSessionId') || undefined;
  const queryParams = `?${
    typeof market !== 'undefined' ? `market=${market}&` : ''
  }${typeof country !== 'undefined' ? `country=${country}&` : ''}${
    typeof cookiesAllowed !== 'undefined'
      ? `cookiesAllowed=${cookiesAllowed}&`
      : ''
  }${typeof errorCode !== 'undefined' ? `errorCode=${errorCode}&` : ''}${
    typeof loggerEid !== 'undefined' ? `loggerEid=${loggerEid}&` : ''
  }${typeof origin !== 'undefined' ? `origin=${origin}` : ''}`;

  const URL = `${AUDIT_URL}/age-gateway/logger${queryParams}`;

  try {
    result = await fetch(URL, {
      headers: { 'X-Forwarded-PathParams': window.location.pathname }
    });

    const sessionId = sessionStorage.getItem('cbgccpSessionId');
    // if session id is not set, set it
    if (!sessionId || typeof sessionId === 'undefined') {
      const response = await result.json();
      sessionStorage.setItem('cbgccpSessionId', response?.loggerEid);
    }
  } catch (err) {
    console.error(err);
  }

  return result;
}

export { sendAuditInfo, sendAgeGateAudit, sendErrorLog };

const cbgCompScript = document.getElementById('cbgCompPack');

const scriptSrc = cbgCompScript && cbgCompScript.getAttribute('src');
const initScriptUrl = scriptSrc;
const BASE_URL =
  initScriptUrl?.replace('cbgCompPack.js', '') ||
  'https://compliance-pack.carlsberggroup.com/';

const getEnvAlias = (baseUrl: string): 'dev' | 'stg' | 'tst' | 'prd' => {
  if (typeof baseUrl !== 'string') {
    return 'prd';
  }
  if (baseUrl.includes('localhost')) {
    return 'dev';
  }
  if (baseUrl.includes('dev')) {
    return 'dev';
  }
  if (baseUrl.includes('stg')) {
    return 'stg';
  }
  if (baseUrl.includes('tst')) {
    return 'tst';
  }
  return 'prd';
};

// for BE endpoints tst env should point to stg
// there is no tst env in BE
const APIEnv = {
  dev: 'dev',
  stg: 'stg',
  tst: 'stg',
  prd: 'prd'
};

// for BE endpoints tst env should point to stg
// there is no tst env in BE
const APIEnvMedia = {
  dev: 'dev',
  stg: 'stg',
  tst: 'stg',
  prd: 'live'
};

const envVars = {
  dev: {
    UMBRACO_API_KEY: 'environment-1d5b892f-6b2f-4b37-84f8-7b85d5a50647',
    POLICIES_BASE_URL: 'https://compliance.dev.ct-apps.io'
  },
  stg: {
    UMBRACO_API_KEY: 'environment-04ffc80c-a8e0-4695-ba13-8321f1b143f0',
    POLICIES_BASE_URL: 'https://compliance.stg.ct-apps.io'
  },
  tst: {
    UMBRACO_API_KEY: 'environment-04ffc80c-a8e0-4695-ba13-8321f1b143f0',
    POLICIES_BASE_URL: 'https://compliance.tst.ct-apps.io'
  },
  prd: {
    UMBRACO_API_KEY: 'environment-168d6608-744c-442e-ab20-053cf4c545d6',
    POLICIES_BASE_URL: 'https://compliance.carlsberggroup.com'
  }
};

const envAlias = getEnvAlias(BASE_URL);

// use for BE api endpoints
const apiEnv = APIEnv[envAlias] as 'dev' | 'stg' | 'prd';
const apiEnvMedia = APIEnvMedia[envAlias];
const { UMBRACO_API_KEY, POLICIES_BASE_URL } = envVars[envAlias];

// use for FE feature flags
const isDev = envAlias === 'dev';
const isStg = envAlias === 'stg';
const isTst = envAlias === 'tst';
const isPrd = envAlias === 'prd';

export {
  envAlias,
  apiEnv,
  apiEnvMedia,
  isDev,
  isStg,
  isTst,
  isPrd,
  BASE_URL,
  UMBRACO_API_KEY,
  POLICIES_BASE_URL
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const setAgeParam = urlParams.get('cbgC');

const ageGateParam = urlParams.get('cbgAge');

const shouldSetAgeCookie = ageGateParam && setAgeParam === 'true';
const noCookiesParam = urlParams.get('cbgNoCookies');

const isCookiesEnabled = () => {
  if (navigator.cookieEnabled) {
    return true;
  }
  return false;
};

const getCookieValue = (cookieName: string) => {
  let cookieValue;

  const match = document.cookie.match(new RegExp(`(^| )${cookieName}=([^;]+)`));

  if (match) {
    const cookieValueIndex = 2;
    cookieValue = match[cookieValueIndex];
    cookieValue = cookieValue.split(',');
  }

  return cookieValue;
};

function getDomainFromHost(host: string = window.location.host): string {
  // add cases to setOnSubdomain where we want to set cookies on subdomains and not on the domain
  // for example, for preview links, we want to set cookies on the preview subdomain
  const setOnSubDomainCases = ['netlify.app', 'amplifyapp.com'];

  const specialTLDcases = [
    'co.uk',
    'com.hk',
    'com.sg',
    'com.my',
    'com.mm',
    'com.kh'
    // we are adding this for netlify subdomains,
    // because we don't want cookies to be set on the main domain fro netlify links
  ];
  const parts = host.split('.');

  if (parts.length <= 2) {
    return host; // already a root domain
  }

  const lastTwoParts = parts.slice(-2).join('.');

  if (setOnSubDomainCases.includes(lastTwoParts)) {
    return host;
  }

  if (specialTLDcases.includes(lastTwoParts)) {
    return parts.slice(-3).join('.');
  }

  return lastTwoParts;
}

const setBrowserCookie = (
  cookieName: string,
  value: string | number | boolean | { [key: string]: boolean },
  session?: boolean
) => {
  const now = new Date();
  const { host } = window.location;

  const expiresOn = new Date(now);
  expiresOn.setDate(now.getDate() + 365);
  localStorage.setItem(`${cookieName}_created_date`, now.toString());

  const expireDate = session ? 0 : expiresOn.toUTCString();
  // evaluates to hostname with starting `www.` removed
  const domain = getDomainFromHost(host);

  // Domain = 'localhost' does not work with document.cookie method,
  // in this case do not set the Domain attr.
  if (host.split('.').length === 1) {
    // no "." in a domain - it's localhost or something similar
    document.cookie = `${cookieName}=${value}; expires=${expireDate}; path=/; SameSite=Lax;`;
    return;
  }

  document.cookie = `${cookieName}=${value}; expires=${expireDate}; path=/; SameSite=Lax; domain=${domain};`;
};

const deleteCookie = (cookieName: string) => {
  const { host } = window.location;
  // get main domain, without subdomain
  const domain = getDomainFromHost(host);

  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Lax;`;
  // if we are on a subdomain
  if (host !== domain) {
    // delete cookie that matches on subdomain
    document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Lax; domain=${host}`;
  }
  // delete cookie that matches on main domain
  document.cookie = `${cookieName}=;expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/; SameSite=Lax; domain=${domain}`;
};

const checkConsentGiven = () => {
  const consentValues = getCookieValue('cbg_ccp');
  const cbgCookieValues =
    consentValues &&
    consentValues.map((consent) => ({
      type: consent,
      value: true
    }));

  return {
    consentValues,
    noConsentIsGiven: !consentValues,
    cbgCookieValues
  };
};

// if there is a cbg_ccp cookie set on the browser the user already interacted with the cookie form
const userGaveConsent = () => {
  const consentValues = getCookieValue('cbg_ccp');
  return !!consentValues;
};

const userConfirmedAge = () => {
  const ageCookie = getCookieValue('cbgAge');
  return !!ageCookie;
};

const isCookiesAllowed = () => {
  const consentValues = getCookieValue('cbg_ccp');
  return (
    !!consentValues &&
    !(consentValues?.length === 1 && consentValues[0] === 'necessary')
  );
};

export {
  ageGateParam,
  shouldSetAgeCookie,
  noCookiesParam,
  isCookiesEnabled,
  getCookieValue,
  setBrowserCookie,
  deleteCookie,
  checkConsentGiven,
  userGaveConsent,
  getDomainFromHost,
  isCookiesAllowed,
  userConfirmedAge
};

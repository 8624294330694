// compliance pack initialization script
// TO DO, this is a temporary index version for testing the consent mode version of this SPA
// please do not replace the original one, instead, merge both when done
import fetchGeoLocation from 'global-utils/fetch-geo-loc';
import { loadCCIfShould, loadAGIfShould } from 'global-utils/load-conditions';
import { getScriptInfo } from 'global-utils/get-attributes';
import { ready } from 'global-utils/doc-state';
import { initGTM } from 'global-utils/GTM-utils';
import { isCookiesEnabled } from './global-utils/cookie-utils';
import { globalScope } from './global-utils/globals';
import { initCConsent } from './global-utils/init-packages';

if (!isCookiesEnabled()) {
  console.warn('cbgCompPack: cookies are disabled, no cookies will be tracked');
}

(async () => {
  ready(() => {
    /*  const loadingAnimation = [
      { transform: 'rotate(0deg)' },
      { transform: 'rotate(360deg)' }
    ];

    const loadingTiming = {
      duration: 1000,
      iterations: Infinity
    };

    const loadingScreen = document.createElement('div');
    loadingScreen.setAttribute('id', 'cbg-loading-screen');
    loadingScreen.style.position = 'fixed';
    loadingScreen.style.top = '0';
    loadingScreen.style.left = '0';
    loadingScreen.style.width = '100%';
    loadingScreen.style.height = '100%';
    loadingScreen.style.backgroundColor = 'rgba(255, 255, 255, 0.9)';
    loadingScreen.style.display = 'flex';
    loadingScreen.style.justifyContent = 'center';
    loadingScreen.style.alignItems = 'center';
    loadingScreen.style.zIndex = '99999998';
    loadingScreen.innerHTML = `<div class="cbg-loading-screen__loader--spinner" style="position: absolute; height: 70px; width: 70px;
          box-sizing: border-box; background: conic-gradient(from 90deg at 50% 50%, rgba(39, 174, 96, 0) 0deg, rgba(31, 144, 255, 0) 65deg, #212833 360deg); border-radius: 70px;
           mask:radial-gradient(farthest-side,#0000 calc(100% - 3px),#fff 0);">
          <div style=" position: absolute; right: 0; top: 50%; transform: translateY(-50%); height: 3px; width: 3px; background: #212833; border-radius: 3px;"></div>
          </div>`;

    const spinner = loadingScreen.querySelector(
      '.cbg-loading-screen__loader--spinner'
    );
    spinner.animate(loadingAnimation, loadingTiming);

    document.body.appendChild(loadingScreen); */
  });

  const geoLocation = await fetchGeoLocation();

  const userLocation = geoLocation?.country?.isoCode;

  loadAGIfShould(userLocation);

  const reOpenConsent = () => {
    // if the cbgConsent script is not loaded, this reopenConsent function will be called
    // if it is, the reopenConsent inside the cbgConsent App component will be called instead
    initCConsent(userLocation, userContinent);
  };

  const { checkCookies } = getScriptInfo();
  globalScope.manageCookies = reOpenConsent;
  // only trigger GTM related events if the cookies package is supposed to be used
  if (checkCookies) {
    // add GTM container script to the page head
    initGTM();
  }

  const userContinent = geoLocation?.continent?.code;

  loadCCIfShould(userLocation, userContinent);
})();

import { BASE_URL } from 'config/env';
import { ready } from 'global-utils/doc-state';

const getScript = (
  source: string,
  id: string,
  userLocation?: string,
  userContinent?: string
) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    ready(() => {
      const script = document.createElement('script');
      const cgbScripts = document.querySelectorAll('[id^="cbg"]');
      const lastCbgScript = cgbScripts[cgbScripts.length - 1];

      if (id) {
        script.setAttribute('id', id);
      }

      // eslint-disable-next-line func-names
      script.onerror = function () {
        console.error(`Error loading ${this.src}`);
      };

      if (userLocation) {
        script.setAttribute('location', userLocation);
      }

      if (userContinent) {
        script.setAttribute('continent', userContinent);
      }

      script.src = source;
      lastCbgScript.after(script);
    });
  } else {
    console.warn(`script ${id} is already on page`);
  }
};

export const initCConsent = (userLocation: string, userContinent: string) => {
  const bannerSrc = `${BASE_URL}cbgCConsent.js`;
  getScript(bannerSrc, 'cbgCConsent', userLocation, userContinent);
};

export const initAGateway = (userLocation: string) => {
  // render the legacy AG for domains on the legacy list and the new for the rest
  const scriptSrc = `${BASE_URL}cbgAGateway.js`;
  getScript(scriptSrc, 'cbgAGateway', userLocation);
};
